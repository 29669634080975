@font-face {
  font-weight: normal;
  font-family: 'Museo Sans';
  font-style: normal;
  src: url('../fonts/MuseoSans-500.woff2') format('woff2'),
    url('../fonts/MuseoSans-500.woff') format('woff');
}

html {
  font-size: 62.5%; /* 1rem = 10px */
}

body {
  font-family: 'Museo Sans', 'Open Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
